<script>
import {BButton, BCard, BCardHeader, BFormTextarea} from "bootstrap-vue-next";

export default {
  data() {
    return {
      confirmFinishAttendanceModal: false,
      refereeNoteInput: this.refereeNote,
      finishAttendanceInProgress: false,
    };
  },
  watch: {
    refereeNote(newValue) {
      this.refereeNoteInput = newValue;
    },
  },
  name: "AttendanceChecklist",
  components: {BFormTextarea, BCard, BCardHeader, BButton},
  props: ["participants", "eventDayId", "refereeNote"],
  inject: ["sematoClient"],
  methods: {
    submitAttendance(personId, eventDayId, attendanceBegin, attendanceEnd) {
      this.sematoClient
          .put("eventday/attendance", {
            personId: personId,
            eventDayId: eventDayId,
            attendanceBegin: attendanceBegin,
            attendanceEnd: attendanceEnd,
          });
    },
    finishEventDayAttendance(eventDayId) {
      this.finishAttendanceInProgress = true;

      const formData = new FormData();
      formData.append("entityType", "EVENT_DAY");
      formData.append("entityId", eventDayId);
      formData.append("task","ATTENDANCE")
      this.sematoClient
          .put("referee/task/finish", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(() => {
        setTimeout(() => {
          this.finishAttendanceInProgress = false;
        }, 30000);
      });

      this.confirmFinishAttendanceModal = false;
    },
    onRefereeNoteChanged(input, eventDayId) {
      this.sematoClient
          .put("eventday/" + eventDayId + "/note", {
            note: input.target.value
          });
    },
  },
};
</script>

<template>
  <div style="width: 100%">
    <table style="width: 100%">
      <thead>
      <tr>
        <th>#</th>
        <th>Vorname</th>
        <th>Nachname</th>
        <th>Anwesenheit Beginn</th>
        <th>Anwesenheit Ende</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(participant, index) in participants" :key="participant.id">
        <td>{{ index + 1 }}</td>
        <td>{{ participant.firstName }}</td>
        <td>{{ participant.lastName }}</td>
        <td>
          <input
              type="checkbox"
              v-model="participant.attendanceBegin"
              @change="submitAttendance(participant.personId, eventDayId, participant.attendanceBegin, participant.attendanceEnd)"
          />
        </td>
        <td>
          <input
              type="checkbox"
              v-model="participant.attendanceEnd"
              @change="submitAttendance(participant.personId, eventDayId, participant.attendanceBegin, participant.attendanceEnd)"
          />
        </td>
      </tr>
      </tbody>
    </table>
    <div class="mt-3">
      <b-form-textarea v-model="refereeNoteInput" @input="onRefereeNoteChanged($event, eventDayId)"
                       placeholder="Anmerkungen"></b-form-textarea>
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <b-button :loading="this.finishAttendanceInProgress" :disabled="this.finishAttendanceInProgress"
                loading-text="Anwesenheitserfassung wird abgeschlossen..." @click="this.confirmFinishAttendanceModal = !this.confirmFinishAttendanceModal">
        Anwesenheitserfassung abschließen
      </b-button>
    </div>
  </div>

  <div v-if="confirmFinishAttendanceModal" class="modal-overlay">
    <div class="modal-content">
      <b-card>
        <b-card-header class="section">
          <b>Anwesenheitserfassung abschließen?</b>
        </b-card-header>
        <div style="padding: 15px">
          <div class="section">
            <h4>Soll die Anwesenheitserfassung abgeschlossen werden?</h4>
          </div>
          <p/>
          Durch Bestätigen ist kein weiteres Bearbeiten mehr möglich!
        </div>
      </b-card>

      <div class="button-container">
        <button class="cancel-button" @click.stop="this.confirmFinishAttendanceModal = !this.confirmFinishAttendanceModal">Abbrechen</button>
        <button class="confirm-button" @click.stop="finishEventDayAttendance(eventDayId)">Ja</button>
      </div>
    </div>
  </div>

</template>

<style scoped>
</style>