<template>
  <div v-if="!bookingGroup.eventSeries">
    <b-accordion-item v-for="event in bookingGroup.events" v-bind:key="event.Id" v-bind:visible="true">
      <template v-if="!bookingGroup.eventSeries" #title>
        {{ event.details[0].title }}&nbsp;
        <span v-if="isCancelled(event)" class="badge bg-danger">abgesagt</span>
        <span v-else-if="isFinished(event)" class="badge bg-secondary">vergangen</span>
        <span v-else-if="isActive(event)" class="badge bg-success">aktiv</span>
        <span v-else-if="isRescheduled(event)" class="badge bg-warning">in Umplanung</span>
        <span v-else class="badge bg-info">planm&auml;&szlig;ig</span>
        <div v-for="badge in event.badges"
             :key="badge.position">
          <b-badge :class="['badge', badge.styleClass]">{{ badge.text }}</b-badge>
        </div>
      </template>
      <b-card :style="{ border: bookingGroup.eventSeries ? '1px solid black' : 'none' }">
        <b-card-body>
          <event-card v-bind:event="event" v-bind:userType="this.userView"></event-card>
        </b-card-body>
      </b-card>
    </b-accordion-item>
  </div>
  <div v-if="bookingGroup.eventSeries">
    <b-accordion-item v-bind:visible="true">
      <template #title>
        {{ getTitle(bookingGroup) }}&nbsp;
        <div v-if="getUpcomingEvents(bookingGroup.events).length !==0">
          <span v-if="isCancelled(getUpcomingEvents(bookingGroup.events)[0])" class="badge bg-danger">nächstes Modul: abgesagt</span>
          <span v-else-if="isFinished(getUpcomingEvents(bookingGroup.events)[0])" class="badge bg-secondary">nächstes Modul: vergangen</span>
          <span v-else-if="isActive(getUpcomingEvents(bookingGroup.events)[0])" class="badge bg-success">nächstes Modul: aktiv</span>
          <span v-else-if="isRescheduled(getUpcomingEvents(bookingGroup.events)[0])" class="badge bg-warning">nächstes Modul: in Umplanung</span>
          <span v-else class="badge bg-info">nächstes Modul: planm&auml;&szlig;ig</span>
        </div>
      </template>
      <b-row class="mb-3">
        <b-col>
          <div v-if="bookingGroup.eventSeries">
                  <span class="badge badge-informational" style="margin-right: 10px;">{{
                      bookingGroup.eventSeries.contentType
                    }}</span>
            <span class="badge badge-informational"
                  style="margin-right: 10px;">{{ bookingGroup.eventSeries.distributionType }}</span>
            <span class="badge badge-informational" style="margin-right: 10px;">{{
                bookingGroup.eventSeries.lessonsCompleted
              }}/{{ bookingGroup.eventSeries.seriesLessons }} UE abgeschlossen</span>
            <span class="badge badge-informational" style="margin-right: 10px;">{{
                bookingGroup.eventSeries.lessonsBooked
              }} UE gebucht</span>
            <span class="badge badge-informational" style="margin-right: 10px;">{{
                bookingGroup.eventSeries.lessonsOpen
              }} UE offen</span>
          </div>
          <hr v-if="bookingGroup.eventSeries"/>
          <div v-if="bookingGroup.seriesFiles.length > 0" class="mt-3 mb-1">
            <p class="mb-0">Dateien:</p>
            <b-button v-for="file in bookingGroup.seriesFiles" v-bind:key="file" variant="link" class="dlBtn"
                      v-on:click="downloadFile(bookingGroup.eventSeries.seriesId, file, userView)">
              <i class="glyphicon glyphicon-download-alt"></i>
              {{ file }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-card :style="{ border: bookingGroup.eventSeries ? '1px solid black' : 'none' }">
        <b-card-body>
          <b-accordion free>
            <b-accordion-item @show="loadPriorities(bookingGroup.eventSeries.seriesBookingId,$event)"
                              v-if="!bookingGroup.seriesDone && bookingGroup.eventSeries.lessonsOpen > 0"
                              title="Modul(e) buchen">
              <div v-if="isPrioritiesLoading" class="loading-bar-container">
                <div class="loading-bar"></div>
              </div>
              <div v-else>
                <b-accordion>
                  <b-accordion-item v-if="hasRequired" title="Pflichtmodul"
                                    @show="loadModules(bookingGroup.eventSeries.seriesBookingId,'REQUIRED')">
                    <div v-if="isLoading" class="loading-bar-container">
                      <div class="loading-bar"></div>
                    </div>
                    <div v-else>
                      <div class="search-container">
                        <!-- Magnifying Glass Icon -->
                        <button @click="toggleSearch" class="search-btn">
                          <i class="bi bi-search"></i>
                        </button>

                        <!-- Input Field (hidden until clicked) -->
                        <input type="text" v-model="searchQuery" placeholder="Titelsuche"
                               class="search-input" :class="{ 'active': searchActive }"/>
                        <!-- 'X' button to clear input -->
                        <button v-if="searchQuery" @click="clearSearch" class="clear-btn">
                          <i class="bi bi-x"></i>
                        </button>
                        <!-- Sort Icon with Dropdown -->
                        <div class="sort-container">
                          <button @click="toggleSort" class="sort-btn">
                            <i class="bi bi-funnel"></i> <!-- Sort icon -->
                          </button>

                          <!-- Sort Options Dropdown -->
                          <div class="sort-dropdown" v-if="sortActive">
                            <div class="sort-option" @click="setSort('title')">
                              <i class="bi bi-fonts"></i> Sortierung: von A-Z
                            </div>
                            <div class="sort-option" @click="setSort('firstModuleDate')">
                              <i class="bi bi-calendar"></i> Sortierung: nach nächstem Termin
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-for="eventSeriesModule in sortedModules"
                           v-bind:key="eventSeriesModule.id">
                        <module-booking-component
                            :seriesBookingId="eventSeriesModule.seriesBookingId"
                            :eventSeriesModule="eventSeriesModule"/>
                      </div>
                    </div>
                  </b-accordion-item>
                  <b-accordion-item v-if="hasRecommendation" title="Seminarempfehlung"
                                    @show="loadModules(bookingGroup.eventSeries.seriesBookingId,'RECOMMENDED')">
                    <div v-if="isLoading" class="loading-bar-container">
                      <div class="loading-bar"></div>
                    </div>
                    <div v-else>
                      <div class="search-container">
                        <!-- Magnifying Glass Icon -->
                        <button @click="toggleSearch" class="search-btn">
                          <i class="bi bi-search"></i>
                        </button>

                        <!-- Input Field (hidden until clicked) -->
                        <input type="text" v-model="searchQuery" placeholder="Titelsuche"
                               class="search-input" :class="{ 'active': searchActive }"/>
                        <!-- 'X' button to clear input -->
                        <button v-if="searchQuery" @click="clearSearch" class="clear-btn">
                          <i class="bi bi-x"></i>
                        </button>
                        <!-- Sort Icon with Dropdown -->
                        <div class="sort-container">
                          <button @click="toggleSort" class="sort-btn">
                            <i class="bi bi-funnel"></i> <!-- Sort icon -->
                          </button>

                          <!-- Sort Options Dropdown -->
                          <div class="sort-dropdown" v-if="sortActive">
                            <div class="sort-option" @click="setSort('title')">
                              <i class="bi bi-fonts"></i> Sortierung: von A-Z
                            </div>
                            <div class="sort-option" @click="setSort('firstModuleDate')">
                              <i class="bi bi-calendar"></i> Sortierung: nach nächstem Termin
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-for="eventSeriesModule in sortedModules"
                           v-bind:key="eventSeriesModule.id">
                        <module-booking-component :seriesBookingId="bookingGroup.eventSeries.seriesBookingId"
                                                  :eventSeriesModule="eventSeriesModule"/>
                      </div>
                    </div>
                  </b-accordion-item>
                  <b-accordion-item v-if="hasChoice" title="Wahlseminar"
                                    @show="loadModules(bookingGroup.eventSeries.seriesBookingId,'CHOICE')">
                    <div v-if="isLoading" class="loading-bar-container">
                      <div class="loading-bar"></div>
                    </div>
                    <div v-else>
                      <div class="search-container">
                        <!-- Magnifying Glass Icon -->
                        <button @click="toggleSearch" class="search-btn">
                          <i class="bi bi-search"></i>
                        </button>

                        <!-- Input Field (hidden until clicked) -->
                        <input type="text" v-model="searchQuery" placeholder="Titelsuche"
                               class="search-input" :class="{ 'active': searchActive }"/>
                        <!-- 'X' button to clear input -->
                        <button v-if="searchQuery" @click="clearSearch" class="clear-btn">
                          <i class="bi bi-x"></i>
                        </button>
                        <!-- Sort Icon with Dropdown -->
                        <div class="sort-container">
                          <button @click="toggleSort" class="sort-btn">
                            <i class="bi bi-funnel"></i> <!-- Sort icon -->
                          </button>

                          <!-- Sort Options Dropdown -->
                          <div class="sort-dropdown" v-if="sortActive">
                            <div class="sort-option" @click="setSort('title')">
                              <i class="bi bi-fonts"></i> Sortierung: von A-Z
                            </div>
                            <div class="sort-option" @click="setSort('firstModuleDate')">
                              <i class="bi bi-calendar"></i> Sortierung: nach nächstem Termin
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-for="eventSeriesModule in sortedModules"
                           v-bind:key="eventSeriesModule.id">
                        <module-booking-component :seriesBookingId="bookingGroup.eventSeries.seriesBookingId"
                                                  :eventSeriesModule="eventSeriesModule"/>
                      </div>
                    </div>
                  </b-accordion-item>
                </b-accordion>
              </div>
            </b-accordion-item>
            <b-accordion-item v-if="!bookingGroup.seriesDone" title="anstehende Module" v-bind:visible="true">
              <b-accordion v-if="getUpcomingEvents(bookingGroup.events).length !==0">
                <b-accordion-item v-for="event in getUpcomingEvents(bookingGroup.events)" v-bind:key="event.Id"
                                  v-bind:visible="true">
                  <template #title>
                    {{ event.details[0].title }}&nbsp;
                    <span v-if="isCancelled(event)" class="badge bg-danger">abgesagt</span>
                    <span v-else-if="isFinished(event)" class="badge bg-secondary">vergangen</span>
                    <span v-else-if="isActive(event)" class="badge bg-success">aktiv</span>
                    <span v-else-if="isRescheduled(event)" class="badge bg-warning">in Umplanung</span>
                    <span v-else class="badge bg-info">planm&auml;&szlig;ig</span>
                  </template>
                  <event-card v-bind:event="event" v-bind:userType="this.userView"></event-card>
                </b-accordion-item>
              </b-accordion>
              <b-accordion v-else>keine anstehenden Module</b-accordion>
            </b-accordion-item>
            <b-accordion-item title="vergangene Module">
              <b-accordion v-if="getPastEvents(bookingGroup.events).length !==0">
                <b-accordion-item v-for="event in getPastEvents(bookingGroup.events)" v-bind:key="event.Id">
                  <template #title>
                    {{ event.details[0].title }}&nbsp;
                    <span v-if="isCancelled(event)" class="badge bg-danger">abgesagt</span>
                    <span v-else-if="isFinished(event)" class="badge bg-secondary">vergangen</span>
                    <span v-else-if="isActive(event)" class="badge bg-success">aktiv</span>
                    <span v-else-if="isRescheduled(event)" class="badge bg-warning">in Umplanung</span>
                    <span v-else class="badge bg-info">planm&auml;&szlig;ig</span>
                  </template>
                  <b-col>
                    <event-card v-bind:event="event" v-bind:userType="this.userView"></event-card>
                  </b-col>
                </b-accordion-item>
              </b-accordion>
              <b-accordion v-else>keine vergangenen Module</b-accordion>
            </b-accordion-item>
          </b-accordion>
        </b-card-body>
      </b-card>
    </b-accordion-item>
  </div>
</template>

<script>
import ModuleBookingComponent from "@/components/ModuleBookingComponent.vue";
import EventCard from '../components/EventCard.vue'
import {BAccordion, BAccordionItem, BButton, BCard, BCardBody, BCol, BRow, BBadge} from "bootstrap-vue-next";

const timeToleranceMillis = 60 * 1000 * 30;

export default {
  data() {
    return {
      eventSeriesModules: [],
      isLoading: false,
      isPrioritiesLoading: false,
      hasRecommendation: false,
      hasRequired: false,
      hasChoice: false,
      searchQuery: '',
      searchActive: false,
      sortBy: 'firstModuleDate',
      sortActive: false
    };
  },
  props: ['bookingGroup', 'userView'],
  inject: ['sematoClient'],
  watch: {
    // Watch for changes in eventSeriesModules to refresh accordion
    eventSeriesModules: {
      handler() {
      },
      deep: true
    }
  },
  computed: {
    sortedModules() {
      // Make a copy of the filteredModules array and sort it
      return this.filteredModules.slice().sort((a, b) => {
        if (this.sortBy === 'title') {
          return a.title.localeCompare(b.title); // Sort alphabetically by title
        } else if (this.sortBy === 'firstModuleDate') {
          return new Date(a.firstModuleDate) - new Date(b.firstModuleDate); // Sort by date
        }
      });
    },
    filteredModules() {
      // Filter eventSeriesModules based on the search query
      return this.eventSeriesModules.filter(eventSeriesModule => {
        // Check if the title includes the search query
        const titleMatches = eventSeriesModule.title
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());

        // Check if any of the badges include the search query
        const badgesMatch = eventSeriesModule.badges.some(badge =>
            badge.text.toLowerCase().includes(this.searchQuery.toLowerCase())
        );

        // Return true if either the title or any badge matches
        return titleMatches || badgesMatch;
      });
    }
  },
  methods: {
    toggleSort() {
      this.sortActive = !this.sortActive;
    },
    setSort(sortOption) {
      this.sortBy = sortOption;
      this.sortActive = false;
    },
    toggleSearch() {
      this.searchActive = !this.searchActive;  // Toggle the input field

      if (!this.searchActive) {
        this.searchQuery = '';  // Clear the field when untoggled
      }

      this.$nextTick(() => {
        if (this.searchActive) {
          this.$refs.searchInput.focus();  // Focus on the input when active
        }
      });
    },
    clearSearch() {
      this.searchQuery = '';  // Clear the input field value
    },
    loadModules(bookingId, priority) {
      this.eventSeriesModules = []
      this.isLoading = true
      this.searchActive = false
      this.searchQuery = ''
      this.sematoClient.get(`booking/series/${bookingId}/modules/${priority}`)
          .then(response => {
            this.isLoading = false
            this.eventSeriesModules = response.data
          })
          .catch(error => {
            if (error.response && error.response.status >= 400 && error.response.status < 500) {
              // reload if we had a client error
              console.log("Authentication failure, reloading to acquire valid access token")
              window.location.reload();
            }
          });
    },
    loadPriorities(bookingId) {
      this.isPrioritiesLoading = true
      this.sematoClient.get(`booking/series/${bookingId}/modules`)
          .then(response => {
            this.isPrioritiesLoading = false
            if (response.data.includes('REQUIRED')) {
              this.hasRequired = true
            }
            if (response.data.includes('RECOMMENDED')) {
              this.hasRecommendation = true
            }
            if (response.data.includes('CHOICE')) {
              this.hasChoice = true
            }
          })
          .catch(error => {
            if (error.response && error.response.status >= 400 && error.response.status < 500) {
              console.log("Authentication failure, reloading to acquire valid access token")
              window.location.reload();
            }
          });
    },
    downloadFile(eventId, fileName, userType) {
      this.sematoClient
          .get(
              `user/${userType}/download?eventId=${eventId}&fileName=/${fileName}&isEventSeries=true`,
              {responseType: 'arraybuffer'}
          )
          .then(response => {
            // Check if Content-Disposition header is present
            const contentDispositionHeader = response.headers['content-disposition'];

            // Extract filename from content-disposition header if present
            const extractedFileName = contentDispositionHeader
                ? contentDispositionHeader.split('filename=')[1].replace(/"/g, '')
                : fileName;
            const blob = new Blob([response.data], {type: 'application/pdf'});

            // Use FileSaver.js to save the blob as a file
            saveAs(blob, extractedFileName);
          });
    },
    seriesTitle(booking) {
      return booking.eventSeries === null ? "" : booking.eventSeries.seriesTitle;
    },
    isActive(event) {
      if (event.cancelled === true) {
        return false;
      }
      var isActive = false;
      for (var date of event.dates) {
        if (this.isCurrent(date)) {
          isActive = true;
        }
      }
      return isActive;
    },
    isCancelled(event) {
      return event.status === 'CANCELLED';
    },

    isRescheduled(event) {
      return event.status === 'RESCHEDULING';
    },

    isFinished(event) {
      return event.cancelled === false && this.upcomingDates(event).length === 0;
    },
    upcomingDates(event) {
      return event.dates
          .filter(date => date.endTimestamp >= Date.now())
          .sort((a, b) => (a.endTimestamp < b.endTimestamp ? -1 : 1));
    },
    isCurrent(date) {
      return (
          date.startTimestamp - timeToleranceMillis < Date.now() &&
          date.endTimestamp + timeToleranceMillis >= Date.now()
      );
    },
    getUpcomingEvents(events) {
      return events.filter(
          (event) => {
            const upcoming = (date) => date.endTimestamp >= Date.now();
            return event.dates.some(upcoming);
          }
      ).sort(
          (a, b) => {
            return a.dates[0].startTimestamp - b.dates[0].startTimestamp;
          }
      )
    },
    getPastEvents(events) {
      return events.filter(
          (event) => {
            const past = (date) => date.endTimestamp < Date.now();
            return event.dates.every(past);
          }
      ).sort(
          (a, b) => {
            return (a.dates[0] === undefined || b.dates[0] === undefined) ? 0 : new Date(b.dates[0].endTimestamp) - new Date(a.dates[0].endTimestamp);
          }
      )
    },
    getTitle(bookingGroup) {
      if (bookingGroup.eventSeries === null) {
        if (bookingGroup.events.length > 0) {
          return bookingGroup.events[0].details[0].title;
        } else {
          return "";
        }
      } else {
        return bookingGroup.eventSeries.seriesTitle;
      }
    },
  },
  components: {
    ModuleBookingComponent,
    EventCard,
    BAccordion,
    BAccordionItem,
    BButton,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BBadge
  }
};
</script>