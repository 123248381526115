<template>
  <div class="modal-overlay">
    <!-- Display module data -->
    <div class="modal-content">
      <b-card>
        <b-card-header class="section modal-title">
          <b>{{ title }}</b>
        </b-card-header>
        <div style="padding: 15px">
          <slot name="modal-content"></slot>
        </div>
      </b-card>
      <div class="button-container">
        <button class="cancel-button" @click.stop="onAbort">Abbrechen</button>
        <button class="confirm-button" @click.stop="onOk">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import {BCard, BCardHeader} from "bootstrap-vue-next";

export default {
  components: {
    BCardHeader,
    BCard
  },
  props: {
    title: String
  },
  methods: {
    onOk() {
      this.$emit("onOk");
    },
    onAbort() {
      this.$emit("onAbort");
    }
  }
};
</script>