<template>
  <h6>{{ event.details[0].subtitle }}</h6>
  <div>
    <span class="badge badge-informational" style="margin-right: 10px;">{{ event.details[0].contentType }}</span>
    <span class="badge badge-informational" style="margin-right: 10px;">{{ event.details[0].distributionType }}</span>
    <span class="badge badge-informational" style="margin-right: 10px;">{{ event.details[0].lessons }} UE</span>
    <span class="badge badge-informational" style="margin-right: 10px;">{{ event.priority }}</span>
    <span v-for="badge in event.details[0].badges"
          :key="badge.position"
          :class="['badge', badge.styleClass]">
      {{ badge.text }}&nbsp;
    </span>
  </div>
  <hr/>
  <div class="mb-3"></div>
  <p v-if="event.details[0].notifications">{{ event.details[0].notifications }}</p>

  <div class="row justify-content-start">
    <div class="col-12">
      <div v-for="date in upcomingDates(event)" v-bind:key="date.time">
        <div v-if="date.eventDayStatus === 'CANCELLED'">
          <s>
            <p class="date">{{ date.dateString ? date.dateString : date.startTimeString }}</p>
          </s>
        </div>
        <div v-else-if="date.eventDayStatus !== 'CANCELLED'">

          <div v-if="this.userType === 'referee'">
            <b-accordion free>
              <b-accordion-item v-bind:visible="true">
                <template v-slot:title>
                  anstehend: {{ date.dateString ? date.dateString : date.startTimeString }}
                </template>
                <b-accordion free>
                  <div v-if="!past && !event.waitinglist && !isCancelled(event)">
                    <!-- Flexbox container for button and fieldset -->
                    <div style="display: flex; flex-direction: column; align-items: flex-start;">
                      <!-- Button -->
                      <b-button
                          v-if="date.webinar && !past && !event.waitinglist && !isCancelled(event) && date.webinarUrl !== ''"
                          variant="link"
                          class="openBtn"
                          v-on:click="openWebinar(event.id, date.webinarUrl)"
                          style="margin-bottom: 8px;"
                      >
                        Onlinemodul via Zoom beitreten
                      </b-button>

                      <b-button
                          v-if="date.zoomAccount"
                          variant="link"
                          class="openBtn"
                          v-on:click=toggleCredentials
                      >
                        Zugangsdaten anzeigen
                      </b-button>
                      <div v-if="showCredentials" style="padding-left: 8px;">
                        <p>Nutzer: {{ date.zoomAccount.zoomAccountName }}</p>
                        <p>Kennwort: {{ date.zoomAccount.zoomAccountPassword }}</p>
                      </div>
                    </div>
                  </div>
                  <p v-else-if="date.webinar && !past && !event.waitinglist" class="webinar">Online</p>
                  <p v-else-if="!past && event.waitinglist" class="webinar">(Warteliste)</p>
                  <p v-if="!date.webinar && date.locationString" class="location">{{ date.locationString }}</p>
                  <p v-if="date.locationNotification" class="notificaton"><i>{{ date.locationNotification }}</i></p>
                  <b-accordion-item v-if="isStartTimeWithinNextHour(date.startTimestamp)" v-bind:visible="false">
                    <template #title>
                      Anwesenheitsliste
                      <b-badge class="mx-1" v-if="date.attendanceCompleted">erledigt</b-badge>
                      <b-badge class="mx-1 badge-referee-task-open" v-if="!date.attendanceCompleted">offen</b-badge>
                    </template>
                    <attendance-checklist v-if="!date.attendanceCompleted" :participants="date.attendanceList"
                                          :eventDayId="date.id" :refereeNote="date.refereeNote"/>
                    <div v-else>
                      Anwesenheitserfassung abgeschlossen
                    </div>
                  </b-accordion-item>
                  <b-accordion-item>
                    <template #title>
                      Unterlagen
                      <b-badge class="mx-1" v-if="date.materialUploadCompleted">erledigt</b-badge>
                      <b-badge class="mx-1 badge-referee-task-open" v-if="!date.materialUploadCompleted">offen</b-badge>
                    </template>
                    <event-day-file-upload v-if="!date.materialUploadCompleted" :event="event" :eventDayId="date.id"/>
                    <div v-else>
                      Unterlagen-Upload abgeschlossen
                    </div>
                  </b-accordion-item>
                </b-accordion>
              </b-accordion-item>
            </b-accordion>
          </div>

          <div v-if="this.userType === 'person'">
            <p class="date">
              {{ date.dateString ? date.dateString : date.startTimeString }} <i>{{ date.description }}</i></p>
            <div v-if="!past && !event.waitinglist && !isCancelled(event)">
              <!-- Flexbox container for button and fieldset -->
              <div style="display: flex; flex-direction: column; align-items: flex-start;">
                <!-- Button -->
                <b-button
                    v-if="date.webinar && !past && !event.waitinglist && !isCancelled(event) && date.webinarUrl !== ''"
                    variant="link"
                    class="openBtn"
                    v-on:click="openWebinar(event.id, date.webinarUrl)"
                    style="margin-bottom: 8px;"
                >
                  Onlinemodul via Zoom beitreten
                </b-button>
              </div>
            </div>
            <p v-else-if="date.webinar && !past && !event.waitinglist" class="webinar">Online</p>
            <p v-else-if="!past && event.waitinglist" class="webinar">(Warteliste)</p>
            <p v-if="!date.webinar && date.locationString" class="location">{{ date.locationString }}</p>
            <p v-if="date.locationNotification" class="notificaton"><i>{{ date.locationNotification }}</i></p>
          </div>
        </div>
      </div>


      <div v-for="date in pastDates(event)" v-bind:key="date.time">
        <div v-if="this.userType === 'referee'">
          <b-accordion free>
            <b-accordion-item v-bind:visible="false">
              <template v-slot:title>
                vergangen: {{ date.dateString ? date.dateString : date.startTimeString }}
              </template>
              <b-accordion free>
                <b-accordion-item v-bind:visible="false">
                  <template #title>
                    Anwesenheitsliste
                    <b-badge class="mx-1" v-if="date.attendanceCompleted">erledigt</b-badge>
                    <b-badge class="mx-1 badge-referee-task-open" v-if="!date.attendanceCompleted">offen</b-badge>
                  </template>
                  <attendance-checklist v-if="!date.attendanceCompleted" :participants="date.attendanceList"
                                        :eventDayId="date.id" :refereeNote="date.refereeNote"/>
                  <div v-else>
                    Anwesenheitserfassung abgeschlossen
                  </div>
                </b-accordion-item>
                <b-accordion-item>
                  <template #title>
                    Unterlagen
                    <b-badge class="mx-1" v-if="date.materialUploadCompleted">erledigt</b-badge>
                    <b-badge class="mx-1 badge-referee-task-open" v-if="!date.materialUploadCompleted">offen</b-badge>
                  </template>
                  <event-day-file-upload v-if="!date.materialUploadCompleted" :event="event" :eventDayId="date.id"/>
                  <div v-else>
                    Unterlagen-Upload abgeschlossen
                  </div>
                </b-accordion-item>
              </b-accordion>
            </b-accordion-item>
          </b-accordion>
        </div>

        <div v-if="this.userType === 'person'">
          <p v-show="pastDates(event).length > 0" class="mb-2 mt-3">Vergangen:</p>
          <div class="d-flex align-items-center">
            <div v-if="date.eventDayStatus === 'CANCELLED'">
              <s>
                <p class="date">{{ date.dateString ? date.dateString : date.startTimeString }}</p>
              </s>
            </div>
            <div v-else-if="date.eventDayStatus !== 'CANCELLED'">
              <p class="date past-date">{{ date.dateString ? date.dateString : date.startTimeString }}</p>
            </div>
          </div>
        </div>
      </div>


      <b-accordion-item v-if="this.userType === 'referee'">
        <template #title>
          Dein Feedback
            <b-badge class="mx-1" v-if="event.refereeFeedbackCompleted">erledigt</b-badge>
            <b-badge class="mx-1 badge-referee-task-open" v-if="!event.refereeFeedbackCompleted">offen</b-badge>
        </template>
        <div v-if="showFeedback()">
          <div v-if="!event.refereeFeedbackCompleted" class="mt-3 d-flex justify-content-center">
            <b-button @click="openFeedbackForm(event)">
              Bitte gib hier Dein Feedback ab
            </b-button>
          </div>
          <div v-else>
            Vielen Dank für Dein Feedback! (Feedback abgegeben)
          </div>
        </div>
        <div v-else>
          Bitte schließe die Anwesenheitserfassung und den Unterlagen-Upload für alle Termine ab.
        </div>
      </b-accordion-item>

      <b-accordion-item v-if="this.userType === 'referee'" @show="getRefereeAgreementForEvent(event)">
        <template #title>
          Honorarrechnung hochladen
            <b-badge class="mx-1" v-if="event.refereeInvoiceUploadCompleted">erledigt</b-badge>
            <b-badge class="mx-1 badge-referee-task-open" v-if="!event.refereeInvoiceUploadCompleted">offen</b-badge>
        </template>
        <div v-if="event.refereeFeedbackCompleted">
          <div v-if="!event.refereeInvoiceUploadCompleted">
            <referee-agreement-component class="mt-3 mb-5 d-flex justify-content-center" v-if="eventAgreement"
                                         :event-agreement="eventAgreement"/>
            <p>Bitte lade Deine Rechnung entsprechend der RVB hoch, damit wir sie verarbeiten können.</p>
            <p>Es ist nur eine .PDF-Dateien mit einer Maximalgröße von 25 MB zugelassen</p>
            <b-form-file v-model="refereeInvoice" accept=".pdf" class="d-flex justify-content-center"/>
            <div class="mt-3 d-flex justify-content-center">
              <b-button :disabled="this.refereeInvoice == null || this.refereeInvoiceUploadInProgress"
                        loading-text="Honorarrechnung wird hochgeladen..."
                        :loading="this.refereeInvoiceUploadInProgress" @click="postRefereeInvoice()">Honorarrechnung
                hochladen
              </b-button>
            </div>
          </div>
          <div v-else>
            Rechnung abgeschlossen
          </div>
        </div>
        <div v-else>
          Bitte gib zuerst Dein Feedback für die Veranstaltung ab.
        </div>
      </b-accordion-item>
    </div>

    <div class="col-12" v-if="event.files.length > 0">
      <p class="mb-2 mt-3">Dateien:</p>
      <div v-for="file in event.files" v-bind:key="file">
        <b-button variant="link" class="dlBtn" v-on:click="downloadFile(event.id, file, userType)">
          <i class="bi bi-download"></i>
          {{ file }}
        </b-button>
      </div>
    </div>

    <div class="col-12" v-if="event.urls.length > 0">
      <p class="mb-2 mt-3">Links:</p>
      <div v-for="url in event.urls" v-bind:key="url">
        <a :href="url.url" target="_blank">
          <i class="bi bi-link-45deg"></i>{{ url.urlText }}
        </a>
      </div>
    </div>

    <div v-if="this.userType === 'person'">
      <p v-if="!isFinished(event) && !isCancelled(event)" :style="{ color: '#838383' }">Teilnahmebescheinigung (nach
        Veranstaltungsabschluss)</p>
      <p v-else-if="isFinished(event) && !isTNBInList() && endedAtLeast10DaysAgo()" :style="{ color: '#838383' }">
        Teilnahmebescheinigung (noch in Vorbereitung)</p>
    </div>
  </div>
</template>

<script>
import {saveAs} from 'file-saver';
import {BAccordion, BAccordionItem, BBadge, BButton, BFormFile, useToastController} from "bootstrap-vue-next";
import AttendanceChecklist from "@/components/AttendanceChecklist.vue";
import EventDayFileUpload from "@/components/EventMaterialUploadComponent.vue";
import RefereeAgreementComponent from "@/components/RefereeAgreementComponent.vue";

export default {
  name: "EventCard",
  components: {
    RefereeAgreementComponent,
    EventDayFileUpload, AttendanceChecklist, BAccordion, BAccordionItem, BButton, BFormFile, BBadge
  },
  inject: ['sematoClient'],
  data: () => {
    return {
      showCredentials: false,
      eventAgreement: null,
      refereeInvoice: null,
      refereeInvoiceUploadInProgress: false
    };
  },
  setup() {
    const toastController = useToastController();

    return {
      toastController,
    };
  },
  methods: {
    showErrorToast(title, body) {
      this.toastController?.show?.({
        props: {
          title: title,
          body: body,
          variant: 'danger',
          pos: 'middle-center',
        },
      });
    },
    openFeedbackForm(event) {
      const title = event.details[0].number + " " + event.details[0].title;
      const location = event.dates[0].locationString;
      const date = new Date(event.dates[0].startTimestamp);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const firstName = this.$store.getters.user.userViewname.split(' ')[0];
      const lastName = this.$store.getters.user.userViewname.split(' ')[1];

      const url = "https://docs.google.com/forms/d/e/1FAIpQLSeyCJaKFkMlOyURcjHCY9UPmF5i9igvZkO-e-CiqSoy4z6ZfA/viewform"
          + "?entry.1721373382=" + title
          + "&entry.1963049893_year=" + year
          + "&entry.1963049893_month=" + month
          + "&entry.1963049893_day=" + day
          + "&entry.1980071432=" + firstName
          + "&entry.1971335058=" + lastName
          + "&entry.904814365=" + location;

      window.open(url, '_blank');

      const formData = new FormData();
      formData.append('entityType', 'EVENT');
      formData.append('entityId', event.id);
      formData.append('task', 'FEEDBACK');
      this.sematoClient.put('referee/task/finish', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(() => {
            // success
          })
          .catch(() => {
            // error
          });
    },
    postRefereeInvoice() {
      // check for file type and size
      if (this.refereeInvoice.type !== 'application/pdf') {
        this.showErrorToast('Fehler', `Die Datei ${this.refereeInvoice.name} ist keine .PDF-Datei.`);
        return;
      } else if (this.refereeInvoice.size > 25 * 1024 * 1024) {
        this.showErrorToast('Fehler', `Die Datei ${this.refereeInvoice.name} ist zu groß.`);
        return;
      } else if (this.refereeInvoice.size === 0) {
        this.showErrorToast('Fehler', `Die Datei ${this.refereeInvoice.name} scheint fehlerhaft zu sein, da sie eine Größe von 0 KB hat.`);
        return;
      }

      this.refereeInvoiceUploadInProgress = true;
      const formData = new FormData();
      formData.append("entityType", "EVENT");
      formData.append("entityId", this.event.id);
      formData.append("file", this.refereeInvoice);
      formData.append("fileType", "INVOICE");
      this.sematoClient
          .post("referee/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(() => {
      }).catch(() => {
        // error
      });
    },
    getRefereeAgreementForEvent(event) {
      /* SEM-3507 for now don't fetch the agreement, because the data is currently outdated
      if (event.refereeFeedbackCompleted && !event.refereeInvoiceUploadCompleted) {
        this.sematoClient.get('referee/eventagreement/' + event.id)
            .then(response => {
              this.eventAgreement = response.data;
            });
      }*/
    },
    showFeedback() {
      // return false if any date has not completed material upload or attendance
      return !this.event.dates.some(date => !date.materialUploadCompleted || !date.attendanceCompleted);
    },
    isStartTimeWithinNextHour(startTimestamp) {
      const currentTime = Date.now();
      const oneHourBeforeStart = startTimestamp - 60 * 60 * 1000; // 1 hour before start time
      return currentTime >= oneHourBeforeStart;
    },
    toggleCredentials() {
      this.showCredentials = !this.showCredentials;
    },
    seriesTitle(booking) {
      return booking.seriesId === 0 ? "" : booking.seriesTitle;
    },

    isCancelled(event) {
      return event.status === 'CANCELLED';
    },

    isRescheduled(event) {
      return event.status === 'RESCHEDULING';
    },

    isFinished(event) {
      return event.cancelled === false && this.upcomingDates(event).length === 0;
    },

    upcomingDates(event) {
      return event.dates
          .filter(date => date.endTimestamp >= Date.now())
          .sort((a, b) => (a.endTimestamp < b.endTimestamp ? -1 : 1));
    },

    pastDates(event) {
      return event.dates
          .filter(date => date.endTimestamp < Date.now())
          .sort((a, b) => (a.endTimestamp > b.endTimestamp ? -1 : 1));
    },

    endedAtLeast10DaysAgo() {
      const tenDaysAgo = Date.now() - 10 * 24 * 60 * 60 * 1000;

      // Ensure the event ended within the last 10 days
      return this.event?.dates?.some(date => date.endTimestamp > tenDaysAgo && date.endTimestamp < Date.now()) || false;
    },

    isTNBInList() {
      return this.event.files.includes('Teilnahmebescheinigung');
    },

    openWebinar(eventId, url) {
      window.open(url);
    },

    downloadFile(eventId, fileName, userType) {
      this.sematoClient
          .get(
              `user/${userType}/download?eventId=${eventId}&fileName=/${fileName}&isEventSeries=false`,
              {responseType: 'arraybuffer'}
          )
          .then(response => {
            // Check if Content-Disposition header is present
            const contentDispositionHeader = response.headers['content-disposition'];

            // Extract filename from content-disposition header if present
            const extractedFileName = contentDispositionHeader
                ? contentDispositionHeader.split('filename=')[1].replace(/"/g, '')
                : fileName;
            const blob = new Blob([response.data], {type: 'application/pdf'});

            // Use FileSaver.js to save the blob as a file
            saveAs(blob, extractedFileName);
          });
    }
  },
  props: ["event", "past", "userType"]
};
</script>

<style scoped>
.webinar {
  margin-bottom: 0;
  margin-left: 12px;
  color: grey;
}

.openBtn {
  margin-bottom: 0;
  margin-left: 12px;
  padding: 0;
}

.dlBtn {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
}

.date {
  margin-bottom: 0;
  min-width: 170px;
}

.date.past-date {
  color: dimgrey;
}

.location {
  color: grey;
  flex: 0 0 100%; /* flex-grow, flex-shrink, flex-basis */
  margin-bottom: 4px;
}

.notificaton {
  color: grey;
  flex: 0 0 100%; /* flex-grow, flex-shrink, flex-basis */
  margin-bottom: 8px;
}

</style>
