<script>
import {BButton, BCard, BCardHeader, BFormFile, useToastController} from "bootstrap-vue-next";

export default {
  setup() {
    const toastController = useToastController();

    return {
      toastController,
    };
  },
  mounted() {
    this.fileInput = this.$refs.fileInput;
  },
  data() {
    return {
      confirmUploadModal: false,
      noSelectedFileModal: false,
      closeMaterialUploadModal: false,
      uploadInProgress: 0,
      closeMaterialUploadInProgress: false,
      selectedFiles: [],
    };
  },
  components: {BCard, BCardHeader, BFormFile, BButton},
  inject: ["sematoClient"],
  props: ["event", "eventDayId"],
  methods: {
    showErrorToast(title, body) {
      this.toastController?.show?.({
        props: {
          title: title,
          body: body,
          variant: 'danger',
          pos: 'middle-center',
        },
      });
    },
    addFilesToArray(event) {
      Array.from(event.target.files).forEach((file) => {

        // check for file type, size and duplicate
        if (file.type !== 'application/pdf') {
          this.showErrorToast('Fehler', `Die Datei ${file.name} ist keine .PDF-Datei.`);
          return;
        } else if (file.size > 25 * 1024 * 1024) {
          this.showErrorToast('Fehler', `Die Datei ${file.name} ist zu groß.`);
          return;
        } else if (file.size === 0) {
          this.showErrorToast('Fehler', `Die Datei ${file.name} scheint fehlerhaft zu sein, da sie eine Größe von 0 KB hat.`);
          return;
        }
        if (this.selectedFiles.some((existingFile) => existingFile.name === file.name && existingFile.size === file.size)) {
          return;
        }

        this.selectedFiles.push(file);
      });

      // Reset the file input to display empty file input
      setTimeout(() => {
        this.fileInput?.reset();
      }, 0);
    },
    checkFilesNotEmpty() {
      if (this.event.files.length === 0) {
        this.noSelectedFileModal = !this.noSelectedFileModal;
        return;
      }
      this.closeMaterialUploadModal = !this.closeMaterialUploadModal;
    },
    finishMaterialUpload() {
      this.closeMaterialUploadInProgress = true;
      const formData = new FormData();
      formData.append("entityType", "EVENT_DAY");
      formData.append("entityId", this.eventDayId);
      formData.append("task", "MATERIAL_UPLOAD");
      this.sematoClient
          .put("referee/task/finish", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(() => {
        setTimeout(() => {
          this.closeMaterialUploadInProgress = false;
        }, 30000);
      });
      this.closeMaterialUploadModal = false;
    },
    okNoSelectedFileModal() {
      this.closeMaterialUploadModal = !this.closeMaterialUploadModal;
      this.noSelectedFileModal = !this.noSelectedFileModal;
    },
    onOkUploadMaterial() {
      this.confirmUploadModal = !this.confirmUploadModal;
    },
    getCurrentDateWithMinSec() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");

      return `${year}${month}${day}${hours}${minutes}`;
    },
    uploadFiles() {
      this.selectedFiles.forEach(file => {
        const originalName = file.name;
        const timestamp = this.getCurrentDateWithMinSec();
        const newName = `${timestamp}_${originalName}`;

        const fileWithTimeStamp = new File([file], newName, {
          type: file.type,
          lastModified: file.lastModified,
        });

        this.uploadInProgress++;
        const formData = new FormData();
        formData.append("entityType", "EVENT");
        formData.append("entityId", this.event.id);
        formData.append("file", fileWithTimeStamp);
        formData.append("fileType", "MATERIAL");
        this.sematoClient
            .post("referee/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }).then(() => {
          if (this.uploadInProgress === 1) {
            setTimeout(() => {
              this.uploadInProgress--;
            }, 30000);
          } else {
            this.uploadInProgress--;
          }
        }).catch(() => {
          // error
        });
      });

      this.confirmUploadModal = !this.confirmUploadModal;
      this.selectedFiles = [];
    },
    removeFileFromSelectedFiles(fileName) {
      this.selectedFiles = this.selectedFiles.filter(file => file.name !== fileName);
    },
  },
};
</script>

<template>
  <div class="mt-3">
    <form @submit.prevent="checkFilesNotEmpty" enctype="multipart/form-data">
      <b-form-file ref="fileInput" label="Es sind nur .PDF-Dateien mit einer Maximalgröße von 25 MB je Datei zugelassen"
                   @change="addFilesToArray($event)" accept=".pdf" multiple>
      </b-form-file>
      <p></p>
      <div v-if="selectedFiles.length > 0">
        <p>Für Upload ausgewählte Unterlagen:</p>
        <p></p>
        <ol class="file-list">
          <li
              v-for="file in selectedFiles"
              :key="file.name"
              class="file-list-item"
          >
            {{ file.name }}
            <b-button @click="removeFileFromSelectedFiles(file.name)" variant="danger">
              <i class="bi bi-trash"></i>
            </b-button>
          </li>
        </ol>
        <p></p>
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <b-button :loading="this.uploadInProgress > 0" loading-text="Unterlagen werden hochgeladen..."
                  @click="onOkUploadMaterial" :disabled="selectedFiles.length === 0 ">
          Ausgewählte Unterlagen hochladen
        </b-button>
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <b-button :disabled="this.closeMaterialUploadInProgress || this.uploadInProgress > 0"
                  :loading="this.closeMaterialUploadInProgress"
                  loading-text="Unterlagen-Upload wird abgeschlossen..."
                  :variant="this.event.files.length > 0 ? 'success' : 'secondary'" type="submit">
          Unterlagen-Upload abschließen
        </b-button>
      </div>
    </form>
  </div>

  <div v-if="closeMaterialUploadModal" class="modal-overlay">
    <div class="modal-content">
      <b-card>
        <b-card-header class="section">
          <b>Unterlagen-Upload abschließen?</b>
        </b-card-header>
        <div style="padding: 15px">
          <div class="section">
            <h4>Hinweis</h4>
          </div>
          <p/>
          Nach Abschluss des Unterlagen-Uploads ist das Hochladen weiterer Unterlagen für diesen Termin nicht mehr möglich.
          <p/>
          Möchtest du den Unterlagen-Upload abschließen?
        </div>
      </b-card>

      <div class="button-container">
        <button class="cancel-button" @click.stop="closeMaterialUploadModal = !closeMaterialUploadModal">Abbrechen
        </button>
        <button class="confirm-button" @click.stop="finishMaterialUpload">Ja</button>
      </div>
    </div>
  </div>

  <div v-if="noSelectedFileModal" class="modal-overlay">
    <div class="modal-content">
      <b-card>
        <b-card-header class="section">
          <b>Unterlagen-Upload abschließen?</b>
        </b-card-header>
        <div style="padding: 15px">
          <div class="section">
            <h4>Hinweis</h4>
          </div>
          <p/>
          Du hast keine Unterlagen hochgeladen.
        </div>
      </b-card>

      <div class="button-container">
        <button class="cancel-button" @click.stop="noSelectedFileModal = !noSelectedFileModal">Überprüfen</button>
        <button class="confirm-button" @click.stop="okNoSelectedFileModal">Trotzdem fortsetzen</button>
      </div>
    </div>
  </div>

  <div v-if="confirmUploadModal" class="modal-overlay">
    <div class="modal-content">
      <b-card>
        <b-card-header class="section">
          <b>Ausgewählte Unterlagen hochladen?</b>
        </b-card-header>
        <div style="padding: 15px">
          <div class="section">
            <h4>Stelle sicher, dass du an dieser Stelle ausschließlich Unterlagen hochlädst, die für Teilnehmende
              bestimmt sind!</h4>
          </div>
          <p/>
          Alle hochgeladenen Unterlagen stehen den Teilnehmenden <b>sofort</b> im Campus für <b>alle Termine der
          Veranstaltung</b> zur Verfügung.
        </div>
      </b-card>

      <div class="button-container">
        <button class="cancel-button" @click.stop="confirmUploadModal = !confirmUploadModal">Nein</button>
        <button class="confirm-button" @click.stop="uploadFiles">Ja</button>
      </div>
    </div>
  </div>

</template>

<style scoped>
.file-list {
  padding: 20px;
}

.file-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
</style>