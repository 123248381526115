import {createApp} from 'vue'
import {createBootstrap} from 'bootstrap-vue-next'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTimers from 'vue-timers'
import sematoClient from './client/sematoClient.js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import './assets/accordion.css'
import './assets/style.css'

const app = createApp(App)

app.use(VueTimers)
app.use(router)
app.use(store)
app.use(createBootstrap())


app.config.productionTip = false

app.provide('sematoClient', sematoClient)

app.mount("#app");