<template>
  <div class="agreements">
    <table>
      <tbody>
      <tr>
        <td>Honorar</td>
        <td class="right-align">{{ eventAgreement.fee }}</td>
      </tr>
      <tr>
        <td>Fahrtkosten</td>
        <td class="right-align">{{ eventAgreement.fare }}</td>
      </tr>
      <tr>
        <td>Materialkosten</td>
        <td class="right-align">{{ eventAgreement.material }}</td>
      </tr>
      <tr>
        <td>Übernachtungskosten</td>
        <td class="right-align">{{ eventAgreement.accommodation }}</td>
      </tr>
      <tr>
        <td>Vereinbarungen</td>
        <td class="right-align">{{ eventAgreement.arrangements }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "RefereeAgreementComponent",
  props: ['eventAgreement']
};
</script>
<style scoped>
.right-align {
  text-align: right;
}
</style>