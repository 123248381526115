<template>
  <b-accordion-item v-bind:visible="true">
    <template #title>
      {{ bookingGroupSuggestion.title }}&nbsp;&nbsp;
      <span v-if="bookingGroupSuggestion.eventDaySuggestions.some(eventDay => eventDay.provided)"
            class="badge badge-informational">angeboten</span>
    </template>
    <div v-if="bookingGroupSuggestion.badges && bookingGroupSuggestion.badges.length >1">
      <br/>
      <span v-for="badge in bookingGroupSuggestion.badges"
            :key="badge.position"
            :class="['badge', badge.styleClass]">
      {{ badge.text }}&nbsp;
      </span>
      <hr/>
    </div>
    <div class="row" style="padding-top: 2px">
      <referee-agreement-component :eventAgreement="bookingGroupSuggestion.eventAgreement"/>
      <p/>
      <div class="col-auto">
        <div class="dropdown" v-if="!bookingGroupSuggestion.eventDaySuggestions.some(eventDay => eventDay.provided)">
          <select class="form-select border border-dark text-red" v-model="selectedSuggestion" required>
            <option disabled value="">Bitte Termin auswählen</option>
            <option v-for="eventDaySuggestion in bookingGroupSuggestion.eventDaySuggestions"
                    :key="eventDaySuggestion.id" :value="eventDaySuggestion">
              {{ eventDaySuggestion.beginLocation }} - Start: {{ eventDaySuggestion.beginWeekDay }} Uhr,
              {{ eventDaySuggestion.beginDateTimeString }} - Ende:
              {{ eventDaySuggestion.endDateTimeString }} Uhr
            </option>
          </select>
        </div>
        <div v-else>
          <div v-for="eventDaySuggestion in bookingGroupSuggestion.eventDaySuggestions"
               :key="eventDaySuggestion.id" :value="eventDaySuggestion">
            {{ eventDaySuggestion.beginLocation }}<br/> {{ eventDaySuggestion.beginWeekDay }},
            {{ eventDaySuggestion.beginDateTimeString }} Uhr -
            {{ eventDaySuggestion.endDateTimeString }} Uhr
          </div>
        </div>
      </div>
      <div class="col-auto">
        <button v-if="!bookingGroupSuggestion.eventDaySuggestions.some(eventDay => eventDay.provided)"
                class="btn btn-primary-transparent text-red border border-dark" @click="isModalOpen = true">
          Termin anbieten
        </button>
      </div>
      <Teleport to="body">
        <div class="toast-container position-fixed p-3 top-50 end-0 translate-middle-y">
          <BToast v-model="this.toastVisible" class="toast-info">
            <template #title>{{ this.toastMessage }}</template>
          </BToast>
        </div>
      </Teleport>

      <modal-component v-if="isModalOpen && selectedSuggestion !=='' "
                       title="Termin zu folgenden Konditionen anbieten ?"
                       @onOk="onOk(selectedSuggestion.id)"
                       @onAbort="this.isModalOpen = false">
        <template #modal-content>
          <referee-agreement-component :eventAgreement="bookingGroupSuggestion.eventAgreement"/>
          <p/>
          <div class="info-panel">
            Durch die Bestätigung ist keine weitere Terminauswahl mehr möglich.
            <br/>
            Für weitere Informationen oder Fragen nutze bitte den Chat unten rechts in Deinem Browser oder kontaktiere
            uns telefonisch.
          </div>
        </template>
      </modal-component>
    </div>

  </b-accordion-item>
</template>
<script>
import {BAccordionItem, BToast} from "bootstrap-vue-next";
import RefereeAgreementComponent from "@/components/RefereeAgreementComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  inject: ['sematoClient'],
  name: "BookingGroupSuggestion",
  components: {ModalComponent, RefereeAgreementComponent, BAccordionItem, BToast},
  data() {
    return {
      isModalOpen: false,
      selectedSuggestion: "",
      toastMessage: '',
      toastVisible: false
    };
  },
  watch: {
    selectedSuggestion: {
      handler() {
        this.isModalOpen = false
      }
    }
  },
  props: ['bookingGroupSuggestion'],
  methods: {
    onOk(eventDayId) {
      this.sematoClient.put(`eventday/${eventDayId}/accept`)
          .then(() => {
            this.isModalOpen = false
            this.$emit("refresh");
          })
      this.showToast()
    },
    showToast() {
      // Set dynamic toast message
      this.toastMessage = 'Vielen Dank für Dein Terminangebot. Wir werden dieses abschließend prüfen und senden Dir innerhalb weniger Tage die dazugehörigen Referent:innenvereinbarung (RVB) zu.';
      this.toastVisible = true;
    }
  }
};
</script>
